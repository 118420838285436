import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProductSerieView from'../views/product/ProductSerieView.vue'
import CompanyStrengthView from'../views/brand/CompanyStrengthView.vue'
import BrandView from '../views/brand/BrandView.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/brand',
    name: 'brandView',
    component:BrandView,
    children:[
      {
        //公式实力
        path: 'companyStrength',
        component: CompanyStrengthView
        //() => import CompanyStrengthView from('../views/brand/CompanyStrengthView.vue'),
      },
      {
        //企业文化
        path: 'companyCulture',
        component: () => import('../views/brand/CompanyCultureView.vue'),
      },


      {
        //创始人介绍
        path: 'companyFounder',
        component: () => import('../views/brand/CompanyFounderView.vue'),
      },
      {
        //展厅
        path: 'companyShowroom',
        component: () => import('../views/brand/CompanyShowroom.vue'),
      },
      {
        //研发中心
        path: 'companyRandDCenter',
        component: () => import('../views/brand/CompanyRandDCenterView.vue'),
      },
      {
        //专利证书
        path: 'companyPatent',
        component: () => import('../views/brand/CompanyPatentView.vue'),
      },
      {
        //新闻
        path: 'companyNews',
        component: () => import('../views/brand/CompanyNewsView.vue'),
      },

    ]
    
  },
  {
    path: '/news/:id',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/newsView.vue')
  },
  {
    path: '/product',
    name: 'product',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/product/ProductView.vue')
  },
  {
    path: '/product/ty',
    name: 'productTy',
    //庭院门
    component: () => import('../views/product/ProductTingyuanmenView.vue')
  },
  {
    path: '/product/xt',
    name: 'productXt',
    // 系统门
    component: () => import('../views/product/ProductXitongmenView.vue')
  },
  {
    path: '/product/serieView/:name',
    name: 'productSerieView',
    // 系统门
    component: ProductSerieView
    //() => import(/* webpackChunkName: "about" */ '../views/product/ProductSerieView.vue')
  },
  {
    path: '/product/productSingleView/:id',
    name: 'productSingleView',
    // 系统门
    component: () => import('../views/product/ProductSingleView.vue')
  }
  ,
  {
    path: '/class',
    name: 'classView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/class/ClassView.vue')
  },
  {
    path: '/custom',
    name: 'CustomView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/custom/CustomView.vue')
  },
  {
    path: '/case',
    name: 'CaseView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/case/CaseView.vue')
  },
  {
    path: '/store',
    name: 'StoreAppreciationView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/store/StoreAppreciationView.vue')
  },
  {
    path: '/investment',
    name: 'InvestmentView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/investment/InvestmentView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
