import request from '@/utils/request'

// 查询分类列表
export function list(query) {
  return request({
    url: '/productCategoryInfo/list',
    method: 'get',
    params: query
  })
}

// 查询部门列表（排除节点）
export function listExcludeChild(id) {
  return request({
    url: '/productCategoryInfo/list/exclude/' + id,
    method: 'get'
  })
}

// 查询部门详细
export function get(deptId) {
  return request({
    url: '/productCategoryInfo/' + deptId,
    method: 'get'
  })
}

// 新增部门
export function add(data) {
  return request({
    url: '/productCategoryInfo',
    method: 'post',
    data: data
  })
}

// 修改部门
export function update(data) {
  return request({
    url: '/productCategoryInfo',
    method: 'put',
    data: data
  })
}

// 删除部门
export function del(id) {
  return request({
    url: '/newsCategory/' + id,
    method: 'delete'
  })
}
