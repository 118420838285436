<template>
    <div class="brandView">
        <el-image style="width: 100%"
            src="https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-03-02/5bf4bc2f-b362-41d3-b07c-92b0de915c12.jpg"
            fit="fit"></el-image>
        <div class="brandNa">
            <div class="brandNav">
                <el-menu background-color="" :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" router>
                    <el-menu-item index="/brand/companyStrength">公司实力</el-menu-item>
                    <el-menu-item index="/brand/companyCulture">企业文化</el-menu-item>
                    <el-menu-item index="/brand/companyFounder">创始人介绍</el-menu-item>
                    <el-menu-item index="/brand/companyShowroom">展厅</el-menu-item>
                    <el-menu-item index="/brand/companyPatent">专利证书</el-menu-item>
                    <el-menu-item index="/brand/companyNews">公司新闻</el-menu-item>

                </el-menu>
            </div>
        </div>
        <router-view></router-view>
        <AreaSearchCom/>
    </div>
</template>
<script>
import AreaSearchCom from '@/components/AreaSearchCom.vue';


export default {
    name: 'brandView',
    data() {
        return {
            activeIndex: "/brand/companyStrength",
        };
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
            this.activeIndex = key;
            //console.log("handleSelect    " + this.activeIndex + "    " + this.$route.path);
        }
    },
    mounted() {
        this.activeIndex = this.$route.path;
        // console.log("brandView    " + this.activeIndex + "    " + this.$route.path);
    },
    watch: {
        $route(now, old) {
            // now: 变化之后的数据
            // old: 变化之前的数据
            console.log("地址栏         " + now, old);
            this.activeIndex = this.$route.path;
        }
    },
    components: { AreaSearchCom }
}
</script>
<style scoped lang="scss">
.brandView {
    margin: 0;
    padding: 0;
    margin-top: -64px;

    .brandNa{
        background-color: #D7D7D7;
    .brandNav {
        margin: auto;
        width: 80%;
        .el-menu--horizontal {
            border-bottom: none;
        }
        .el-menu-item {
            width: 16.5%;
           
        }
        .el-menu{
            background-color: #D7D7D7;
        }

        .is-active {
            background-color: #56504A;
            color: white;
        }

     
    }
}
}</style>