var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"CompanyStrengthView"},[_c('div',{staticClass:"part1"},[_c('div',[_vm._v(_vm._s(_vm.yearNum)+"年发展见证品牌实力")]),_c('div',{staticStyle:{"margin-top":"-30px"}},[_vm._v("__")])]),_vm._m(0),_c('div',{staticClass:"part3"},[_c('div',[_vm._v("发展规模")]),_c('div',{staticStyle:{"margin-top":"-10px"}},[_vm._v("__")]),_c('div',{staticClass:"small"},[_vm._v("202个城市35000名客户参与调研，无数个选择庭院门的考量引我们研发的方向")]),_c('el-image',{staticStyle:{"width":"50%"},attrs:{"src":"https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-03-04/db496761-9894-4b87-bbfe-67563618e16f.png","fit":"fit"}})],1),_vm._m(1),_c('div',{staticClass:"part4"},[_c('div',{staticClass:"show"},[_vm._m(2),_c('el-image',{staticStyle:{"width":"40%"},attrs:{"src":"https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-03-04/f92009ce-63dc-4cd8-962b-f7a45bf248ae.png","fit":"fit"}})],1),_c('div',{staticClass:"show2"},[_c('el-image',{staticStyle:{"width":"60%"},attrs:{"src":"https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-03-04/a850ab64-36c1-4cf6-868a-636792ba9e10.png","fit":"fit"}}),_vm._m(3)],1),_c('div',{staticClass:"show"},[_vm._m(4),_c('el-image',{staticStyle:{"width":"40%"},attrs:{"src":"https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-03-04/7bf1d3f4-e874-41c8-8ac4-9555637a5d18.png","fit":"fit"}})],1),_c('div',{staticClass:"show2"},[_c('el-image',{staticStyle:{"width":"60%"},attrs:{"src":"https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-03-04/226ab032-9b74-42b6-ad3a-547891181690.png","fit":"fit"}}),_vm._m(5)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part2"},[_c('div',{staticClass:"title"},[_vm._v("成立，英伦风尚")]),_c('div',{staticClass:"years"},[_c('div',[_c('div',[_vm._v("2016")]),_c('div',[_vm._v("年")])]),_c('div',[_c('div',[_vm._v("2016")]),_c('div',[_vm._v("年")])]),_c('div',[_c('div',[_vm._v("2016")]),_c('div',[_vm._v("年")])]),_c('div',[_c('div',[_vm._v("2016")]),_c('div',[_vm._v("年")])]),_c('div',[_c('div',[_vm._v("2016")]),_c('div',[_vm._v("年")])]),_c('div',[_c('div',[_vm._v("2016")]),_c('div',[_vm._v("年")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part1"},[_c('div',[_vm._v("科技强企硬实力")]),_c('div',{staticStyle:{"margin-top":"-30px"}},[_vm._v("__")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"loc"},[_c('div',{staticClass:"title4"},[_vm._v("武汉分公司200余亩厂区")]),_c('div',[_vm._v(" 是非成败转头空，青山依旧在，惯看秋月春风。一壶浊酒相逢古今多少事，滚滚长江东逝水，浪花淘尽英是非成败转头空青山依旧在，惯看秋月春风。一壶浊酒赛相逢，古今多少事，滚滚长江东逝水，浪花淘尽英是非成败转头空，青山依旧在，看秋月春风。一壶浊酒喜相逢，古今多少事，滚滚长江东逝水，浪花淘尽英 ")]),_c('div',[_vm._v("___")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"loc"},[_c('div',{staticClass:"title4"},[_vm._v("永康总公司200余亩厂区")]),_c('div',[_vm._v(" 是非成败转头空，青山依旧在，惯看秋月春风。一壶浊酒相逢古今多少事，滚滚长江东逝水，浪花淘尽英是非成败转头空青山依旧在，惯看秋月春风。一壶浊酒赛相逢，古今多少事，滚滚长江东逝水，浪花淘尽英是非成败转头空，青山依旧在，看秋月春风。一壶浊酒喜相逢，古今多少事，滚滚长江东逝水，浪花淘尽英 ")]),_c('div',[_vm._v("___")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"loc"},[_c('div',{staticClass:"title4"},[_vm._v("11000平方英伦风尚新展厅")]),_c('div',[_vm._v(" 是非成败转头空，青山依旧在，惯看秋月春风。一壶浊酒相逢古今多少事，滚滚长江东逝水，浪花淘尽英是非成败转头空青山依旧在，惯看秋月春风。一壶浊酒赛相逢，古今多少事，滚滚长江东逝水，浪花淘尽英是非成败转头空，青山依旧在，看秋月春风。一壶浊酒喜相逢，古今多少事，滚滚长江东逝水，浪花淘尽英 ")]),_c('div',[_vm._v("___")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"loc"},[_c('div',{staticClass:"title4"},[_vm._v("机器设备")]),_c('div',[_vm._v(" 公司斥巨资从德国、意大利等国家引进全套生产设备，主要设备选用童大利5CM和BESSE机械设备及德国豪迈HOMAG进口设备设备精度达到02mm，产品制造一体化，采用一流工艺技术，是目前国内具实力的整体木作服务商之一。 ")]),_c('div',[_vm._v("___")])])])
}]

export { render, staticRenderFns }