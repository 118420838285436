import request from '@/utils/request'




// 查询用户列表
export function list(query) {
  return request({
    url: '/newsInfo/list',
    method: 'get',
    params: query
  })
}

// 查询用户详细
export function getInfo(id) {
  return request({
    url: '/newsInfo/' + id,
    method: 'get'
  })
}

// 新增用户
export function add(data) {
  return request({
    url: '/newsInfo',
    method: 'post',
    data: data
  })
}

// 修改用户
export function update(data) {
  return request({
    url: '/newsInfo',
    method: 'put',
    data: data
  })
}

// 删除用户
export function del(userId) {
  return request({
    url: '/newsInfo/' + userId,
    method: 'delete'
  })
}

export function imageFileUpload(file) {
  return request({
    url: '/imgUtil/upload',
    method: 'post',
    headers: { // 请求头
      'Content-Type': 'multipart/form-data' // 上传文件所要求的格式formdata
    },
    data: file
  })
}



